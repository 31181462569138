#Sidemenu {
    --bg: var(--white);
    --color: var(--black);
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-xs);
}

#Sidemenu {
    @include z-index($z-index-sidemenu);

    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: calc(100vw / var(--cols));
    padding-top: var(--header-height);

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    & > .content {
        @include z-index(1);
        position: relative;

        @include font-sans();
        color: var(--color);

        height: calc(var(--vh, 1vh) * 100 - var(--header-height));

        display: flex;
        flex-direction: column;
        
        > div {
            min-height: calc(var(--vh, 1vh) * 100 - var(--header-height));
            display: flex;
            flex-direction: column;
        }

        .top {
            padding: var(--padding-v) var(--padding-h);

            p {
                height: calc(var(--font-size-base) * var(--line-height-sans));
            }
        }

        .filters {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            .button {
                background-color: transparent; 
                transition: background .2s ease-out;
            }
        }

        .button {
            a {
                width: 0;
                height: 0;
                visibility: hidden;
            }
        }
    }

    .content-overlay {
        @include z-index(0);
        height: 100%;
        width: 100%;
        right: 0;
        position: absolute;
        top: 0;
        background-color: var(--bg);
        transform: translateY(-101%);
        transition: transform .3s ease-out;
    }

    &.--animated {
        .content-overlay {
            transform: translateY(0);
            transition: transform .4s ease-in-out;
        }

        .filters {
            .button {
                background-color: var(--grey);
                transition: background .3s ease-in-out;
                transition-delay: .2s;
            }
        }
    }
}
