.block-slider {
    --width-slide: 100%;
    --height: 100%;
    
    @media (max-width: $smartphone) {
        --height: 70vh;
    }

    position: relative;
    overflow: hidden;

    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    
    cursor: grab;    
    &:active {
        cursor: grabbing;
    }

    .slider {
        height: var(--height);
    }
    
    .holder {
        order: 1;
        position: relative;
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
    }
    
    &__item {
        position: relative;
        flex: 0 0 var(--width-slide);
        width: var(--width-slide);
        height: 100%;
        overflow: hidden;

        * {
            pointer-events: none;
            user-select: none;
        }

        figure {
            video,
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                -webkit-user-drag: none;
                -khtml-user-drag: none;
                -moz-user-drag: none;
                -o-user-drag: none;
                user-drag: none;
            }
        }
    }

    &__controls {
        @include font-sans();

        width: 100%;
        
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            padding: var(--padding-xxs) var(--padding-xs);
            color: var(--white);
            background-color: var(--black);
            height: var(--header-height);
        }
    }
}
