.block-image {
    position: relative;
    overflow: hidden;
    
    * {
        pointer-events: none;
        user-select: none;
    }

    .text {
        @include font-sans();

        color: var(--white);
        background-color: var(--black);

        height: var(--header-height);
        width: 100%;
        padding: var(--padding-xxs) var(--padding-xs);

        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    figure {  
        video,
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            display: block;
        }
    }

    .layer {
        @include basic-a();
        @include font-sans();

        color: var(--black);
        background-color: rgba(0, 0, 0, .5);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        
        opacity: 0;
        visibility: hidden;

        .button {
            --colorHover: var(--white);
            color: var(--white);
        }
    }
    
    @include isCursor() {
        &:hover {
            .layer {
                pointer-events: initial;
                opacity: 1;
                visibility: visible;

                .button {
                    .overlay::before {
                        transition-delay: 0s;
                    }

                    .overlay::after {
                        transition-delay: .15s;
                    }
                    
                    &::after {
                        transition-delay: .3s;
                    }

                    .overlay::before,
                    .overlay::after,
                    &::after {
                        transform: translateY(0);
                    }
                }

                // > span {
                    // .overlay::before,
                    // .overlay::after,
                    // &::after {
                    //     transform: translateY(0);
                    // }
            
                    // .overlay::before {
                    //     transition-delay: 0s;
                    // }
            
                    // .overlay::after {
                    //     transition-delay: .15s;
                    // }
                    
                    // &::after {
                    //     transition-delay: .3s;
                    // }
                // }
            }
        }
    }
}
