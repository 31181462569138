#Preloader {
    --color: var(--pink);
    --bg: var(--black);
}

#Preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    @include z-index($z-index-loader);
    color: var(--color);
    background-color: var(--bg);
    
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;

    @include font-sans();

    text-align: left;

    .wrapper {
        position: relative;
        height: calc(var(--font-size-base) * var(--line-height-sans) * 2);

        > *:not(.wrapper__preload) {
            opacity: 0;
        }
    }

    .wrapper__preload {
        position: absolute;
        top: 0;
        left: 0;

        span {
            display: block;
            min-height: calc(var(--font-size-base) * var(--line-height-sans));
        }
    }


}
