.project {
    --contentCols: 3;
    --row-size: #{rem(320)};
}

.project {
    padding-top: var(--header-height);
    position: relative;
    min-height: 100vh;

    > .content {
        opacity: 0;
    }

    &.--show {
        opacity: 1;

        > .content {
            opacity: 1;
        }
    }

    &.--animated {
        opacity: 1;

        > .content {
            opacity: 1;
            transition-delay: .4s;
        }
    }

    @media (max-width: $smartphone) {
        .spacer {
            display: none;
        }

        .block-image {
            > .content > figure.media-holder {
                --padding: calc(100% * var(--aspect));
            }
        }
    }

    @media (min-width: $smartphone) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        transition: .7s var(--ease-out-quart) .4s;

        .blocks {
            display: grid;
            grid-template-columns: repeat(var(--contentCols), 1fr);
            grid-auto-rows: var(--row-size); 
        }

        .spacer {
            grid-column: span 1;
        }

        > .content {
            grid-column: span 2;
        }

        .block-text {
            grid-row: span 1;
            grid-column: span 1;
        }
        
        .block-image {
            > .content {
                max-height: 100%;
                min-height: 100%;
            }
            
            > .content > figure {
                position: absolute;
                top:0;
                left:0;
                display: block;
                width: 100%;
                height: 100%;
                
                img,
                video {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .block-slider,
        .block-spacer,
        .block-image {
            grid-row: span var(--rows);
            grid-column: span var(--cols);
        }
    }
}

.main-overlay {
    position: fixed;
    @include z-index($z-index-project-overlay);
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    > span {
        position: absolute;
        display: block;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        transform: scale3d(1,0,1);
        transform-origin: 0 0;

        &:nth-child(1) {
            background-color: var(--blue);
        }

        &:nth-child(2) {
            background-color: var(--pink);
        }

        &:nth-child(3) {
            background-color: var(--black);
        }
    }

    &.--invert {
        > span {
            transform-origin: 100% 100%;
        }
    }
}
