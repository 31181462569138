#Interface__Canvas,
#scene-three,
#scene-target {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

#Interface__Canvas {
    @include z-index($z-index-interface);
}

#scene-three,
#scene-target {
    @include z-index($z-index-bg);
}

#scene-target {
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-xs);
    --screenSize: 100vw;
    --targetWidth: 170vw;
    --top: 50vw;
    --left: calc((var(--screenSize) - var(--targetWidth)) / 2);
    
    @media (min-width: $smartphone) {
        --targetWidth: 100vh;
        --top: 15vw;
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-xs);
    }

    @media (min-width: $tabletPortrait) {
        --top: 35vw;

        .--works &,
        .--project & {
            --left: calc(var(--screenSize) - var(--targetWidth));
        }
        
        .--data &  {
            --left: 0;
        }
    }
    
    @media (min-width: $tabletLandscape) {
        --top: 30vh;
    }

    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: calc(var(--header-height) + var(--padding-v)) var(--padding-h) var(--padding-v);

    .target {
        width: var(--targetWidth);
        height: var(--targetWidth);
        top: var(--top);
        position: absolute;
        left: var(--left);
    }

    .target-name {
        width: 100%;
        height: auto;
        position: relative;
        
        .icon {
            position: absolute;

            width: 100%;
            display: block;
    
            svg {
                display: block;
                fill: transparent;
                stroke: var(--pink);
            }
        }

        &.--add-color {
            .icon svg {
                fill: var(--pink);
                transition: fill 2s 2.5s var(--ease-in-out-quad);
            }
        }
    }
}
