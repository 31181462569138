@mixin button {
    --padding-v-tab: var(--padding-xxs);
    --padding-h-tab: var(--padding-xs);
    --color: var(--black);
    --colorHover: var(--white);
    --overlay: var(--black);
    
    &.--pink {
        --overlay: var(--pink);
        --colorHover: var(--black);
    }

    @include basic-a();
    @include font-sans();

    height: var(--header-height);
    padding: var(--padding-v-tab) var(--padding-h-tab);
    position: relative;
    overflow: hidden;
    text-align: left;
    color: var(--color);

    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .content {
        @include z-index(3);
        position: relative;
    }

    .overlay::before,
    .overlay::after,
    &::after {
        content: '';
        
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;

        pointer-events: none;

        transition: transform .15s ease-in-out;
    }
    
    .overlay::before {
        @include z-index(0);
        transform: translateY(-101%);
        background: var(--blueDark);
        transition-delay: .1s;
    }
    
    .overlay::after {
        @include z-index(1);
        transform: translateY(-101%);
        background: var(--blue);
        transition-delay: .05s;
    }
    
    &::after {
        @include z-index(2);
        transform: translateY(101%);
        background: var(--overlay);
        transition-delay: .1s;
    }

     @include isCursor() {
        &:hover {
            --color: var(--colorHover);

            .overlay::before {
                transition-delay: 0s;
            }

            .overlay::after {
                transition-delay: .15s;
            }
            
            &::after {
                transition-delay: .3s;
            }

            .overlay::before,
            .overlay::after,
            &::after {
                transform: translateY(0);
            }
        }
    }

    [aria-expanded="true"] > &,
    &.--active {
        --color: var(--colorHover);

        svg {
            fill: var(--bg);
        }

        .overlay::before {
            transition-delay: 0s;
        }

        .overlay::after {
            transition-delay: .15s;
        }
        
        &::after {
            transition-delay: .3s;
        }

        .overlay::before,
        .overlay::after,
        &::after {
            transform: translateY(0);
        }
    }
}

.button {
    @include button;
}
