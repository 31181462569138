@use "sass:math";

.block-legal,
.block-wysiwyg {
    --font-size-h1: var(--font-size-base);
    --font-size-h2: var(--font-size-base);
    --font-size-h3: var(--font-size-base);
    --font-size: var(--font-size-base);
    --color: var(--primary-color);

    --max-width: #{math.div(1500px, 16px) * 1rem};
    --margin-v: 0;
    --padding-v: 10vh;
    --padding-h: 25%;

    @media (max-width: $smartphone) {
        --padding-h: 20px;
    }      
}

.block-legal {
    background-color: var(--white);
    min-height: 100vh;
}

.block-legal,
.block-wysiwyg {
    color: var(--primary-color);

    @include font-sans();
    font-size: var(--font-size);
    max-width: var(--max-width);
    margin: var(--margin-v) auto;
    padding: var(--padding-v) var(--padding-h);

    h1 {
        @include font-sans(1);
        font-size: var(--font-size-h1);
        margin: .5em 0;
    }

    h2 {
        @include font-sans(1);
        font-size: var(--font-size-h2);
        margin: .5em 0;
    }

    h3 {
        @include font-sans(1);
        font-size: var(--font-size-h3);
        margin: .5em 0;
    }

    svg {
        max-width: 100px;
        height: auto;
    }

    p {
        i {
            @include font-sans();
        }

        strong,
        b {
            @include font-sans();
        }
        margin: .5em 0;
    }

    ul, ol {
        margin: 2em 0;
        padding-left: 4em;
    }

    a {
        @include basic-a();
        @include font-sans();
        text-decoration: underline;
        color: inherit;
    }
}

