@use "sass:math";

:root {
    --y-header: 0;

    --padding-m: #{math.div(60px, 16px) * 1rem};
    --padding-s: #{math.div(30px, 16px) * 1rem};
    --padding-xs: #{math.div(15px, 16px) * 1rem};
    --padding-xxs: #{math.div(8px, 16px) * 1rem};

    --header-height: #{math.div(30px, 16px) * 1rem};
    --scrollbar-height: #{math.div(40px, 16px) * 1rem};
    --cols: 3;

    @media (max-width: $smartphone) {
        --cols: 1;
    }
}

@media (max-width: $smartphone) {
    :root {}
}
