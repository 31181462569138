#Bio {
    --bg: var(--white);
    --color: var(--black);
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-xs);
}

#Bio {
    @include z-index($z-index-sidemenu);

    height: 100vh;
    right: 0;
    position: fixed;
    top: 0;
    width: calc(100vw / var(--cols));
    padding-top: var(--header-height);
    // overflow: hidden;

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    & > .content {
        @include z-index(1);
        position: relative;

        @include font-sans();
        color: var(--color);

        height: calc(var(--vh, 1vh) * 100 - var(--header-height));

        display: flex;
        flex-direction: column;

        > div {
            > div {
                min-height: calc(var(--vh, 1vh) * 100 - var(--header-height));
                display: flex;
                flex-direction: column;
            }
        }

        .top {
            padding: var(--padding-v) var(--padding-h);

            p {
                height: calc(var(--font-size-base) * var(--line-height-sans));
            }
        }

        .list {
            flex-grow: 1;

            li {
                display: block;
            }
        }
    }

    .content-overlay {
        @include z-index(0);
        height: 100%;
        width: 100%;
        right: 0;
        top: 0;
        position: absolute;
        background-color: var(--bg);
        transform: translateY(-101%);
        transition: transform .3s ease-out;
    }

    .footer {
        .text {
            transition: background .2s ease-out;
            padding: var(--padding-v) var(--padding-h);
        }

        .button-fake {
            transition: background .2s ease-out;
            height: var(--header-height);
            padding: var(--padding-xxs) var(--padding-xs);
            background-color: transparent;
            color: var(--white);
        }

        .separador-legal {
            height: 3em;
        }
    }

    &.--animated {
        .content-overlay {
            transform: translateY(0);
            transition: transform .4s ease-in-out;
        }

        .footer {
            .text {
                transition: background .4s ease-in-out;
                background-color: var(--pink);
                transition-delay: .5s;
            }

            .button-fake {
                transition: background .4s ease-in-out;
                background-color: var(--black);
                transition-delay: .5s;
            }
        }
    }

    @media (max-width: $smartphone) {
        & > .content {
            overflow-y: auto;
        }

        .gap {
            display: none;
        }
    }

    @media (min-width: $smartphone) {
        .footer {
            .text {
                p {
                    height: calc(var(--font-size-base) * var(--line-height-sans));
                }
            }
        }
    }
}
