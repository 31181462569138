#Header {
    --width: 100%;
    --height: var(--header-height);
    --color: var(--black);
    --bg: var(--white);
    --padding-v: var(--padding-xxs);
    --padding-h: var(--padding-xs);
    --cols: 3;
}

#Header {
    @include z-index($z-index-header);

    position: fixed;
    width: var(--width);
    height: var(--height);
    right: 0;
    top: 0;
    background-color: var(--bg);

    @include font-sans(1);

    a {
        @include basic-a();
        color: var(--color);
        display: block;
        transition: color .2s ease-out;

        svg {
            transition: fill .2s ease-out;
            fill: var(--color);
        }

        * {
            pointer-events: none;
        }
    }

    .button {
        padding: var(--padding-v) var(--padding-h);  
    }

    .button {
        height: 100%;
        position: relative;
        overflow: hidden;
    }

    .logo {
        text-align: center;
        padding: 0 !important;

        .content {
            width: 100%;
            display: block;
            height: 100%;
        }

        .text,
        .icon {
            padding: var(--padding-v) var(--padding-h);  

            display: flex;
            align-items: center;
            justify-content: center;

            transition: transform .3s ease-out;
            
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }

        .text {
            transform: translateY(-100%);
        }

        .icon {
            transform: translateY(0);
        }
        
        svg {
            height: rem(10);
            width: rem(47);
        }
    }
    
    @include isCursor() {
        .button {
            &:hover {
                color: var(--bg);

                svg {
                    fill: var(--bg);
                }
            }
        }
    }

    .button[aria-current="page"] {
        color: var(--bg);

        .overlay::before {
            transition-delay: 0s;
        }

        .overlay::after {
            transition-delay: .15s;
        }
        
        &::after {
            transition-delay: .3s;
        }

        .overlay::before,
        .overlay::after,
        &::after {
            transform: translateY(0);
        }
    }

    .--home & {
        .logo.button {
            --bg: var(--black);
            --overlay: var(--pink);

            .text {
                transform: translateY(0);
            }

            .icon {
                transform: translateY(-100%);
            }
        }
    }

    .--project & {
        @media (min-width: $smartphone) {
            .works {
                pointer-events: none;
                color: var(--bg);
    
                .overlay::before {
                    transition-delay: 0s;
                }
    
                .overlay::after {
                    transition-delay: .15s;
                }
                
                &::after {
                    transition-delay: .3s;
                }
    
                .overlay::before,
                .overlay::after,
                &::after {
                    transform: translateY(0);
                }
            }
        }
    }

    .--data & {
        .data {
            pointer-events: none;
            color: var(--bg);

            .overlay::before {
                transition-delay: 0s;
            }

            .overlay::after {
                transition-delay: .15s;
            }
            
            &::after {
                transition-delay: .3s;
            }

            .overlay::before,
            .overlay::after,
            &::after {
                transform: translateY(0);
            }
        }
    }

    @media (max-width: $smartphone) {
        display: grid;
        grid-template-columns: 30fr 40fr 30fr;
    }
    
    @media (min-width: $smartphone) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}
