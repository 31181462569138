[data-acordion],
[data-acordion-billboard],
[data-acordion-sidemenu] {
    --height-close: var(--header-height);
    --height-open: 0;
    --duration: .4s;
    --ease: var(--ease-in-out-quad);
    --color: var(--black);
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-xs);
    --bg: var(--grey);

    @include font-sans();

    color: var(--color);
    overflow: hidden;
    min-height: var(--height-close);
    transition: height;
    transition-timing-function: var(--ease);
    transition-duration: var(--duration);

    &[aria-expanded="true"] {
        height: calc(var(--height-close) + var(--height-open));

        [data-acordion-toggle] {
            .content:not(:empty)::before {
                content: '-';
            }
        }
    }

    &[aria-expanded="false"] {
        height: var(--height-close);
        --bg: transparent;
    }

    [data-acordion-toggle] {
        width: 100%;

        * {
            pointer-events: none;
        } 

        .content:not(:empty)::before {
            content: '+';
            margin-right: rem(3);
        }
    }

    [data-acordion-group] {
        background-color: var(--bg);
        padding: var(--padding-v) var(--padding-h);
        transition: background-color .2s ease-out;

        > *:not(:last-child) {
            margin-bottom: var(--padding-xxs);
        }

        ul li,
        ol li {
            &.--animated::before {
                content: '·';
                margin-right: rem(3);
            }
        }
    }
}

[data-acordion-billboard] {
    @media (min-width: $smartphone) {
        display: none;
    }

    .button .content::before {
        display: none;
    }
}

[data-acordion-sidemenu] {
    &[aria-expanded="true"] {
        > .button {
            pointer-events: none;
        }
    }
}