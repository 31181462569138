.block-billboard {
    position: relative;
    overflow: hidden;

    * {
        pointer-events: none;
        user-select: none;
    }

    // figure {
    //     opacity: 0;
    // }
    
    // .overlay {
    //     transform: translateY(0);

    //     &,
    //     span::before,
    //     span::after,
    //     &::after {
    //         content: '';
            
    //         position: absolute;
    //         left: 0;
    //         top: 0;
    //         height: 100%;
    //         width: 100%;

    //         transition: transform .25s ease-in-out;
    //     }

    //     span::before {
    //         @include z-index(0);
    //         transform: translateY(-100%);
    //         background: var(--blueDark);
    //     }
        
    //     span::after {
    //         @include z-index(1);
    //         transform: translateY(-100%);
    //         background: var(--blue);
    //     }
        
    //     &::after {
    //         @include z-index(2);
    //         transform: translateY(100%);
    //         background: var(--black);
    //     }
    // }

    // &.--animated {
    //     .overlay {
    //         transform: translateY(-100%);
    //         transition-delay: .6s;

    //         span::before {
    //             transition-delay: 0s;
    //         }
    
    //         span::after {
    //             transition-delay: .2s;
    //         }
            
    //         &::after {
    //             transition-delay: .4s;
    //         }
    
    //         span::before,
    //         span::after,
    //         &::after {
    //             transform: translateY(0);
    //         }
    //     }

    //     figure {
    //         opacity: 1;
    //         transition-delay: .4s;
    //     }
    // }
}
