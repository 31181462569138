.not-found {
    --color: var(--pink);
    --bg: var(--pink20);
    --padding: var(--padding-s);
}

.not-found {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    
    @include z-index($z-index-loader);
    @include font-sans();

    text-align: center;

    padding: calc(var(--header-height) + var(--padding)) var(--padding) var(--padding);

    > div {
        color: var(--color);
        // background-color: var(--bg);
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        max-height: 100%;
        width: 100%;

        align-items: center;
        flex-direction: column;
        display: flex;
        justify-content: center;
    }

    a {
        @include basic-a(); 
        color: var(--color);
        background-color: var(--white);
        justify-content: center;
        margin-top: var(--padding-xs);
    }

    @media (min-width: $smartphone) {
        font-size: var(--font-size-large);
        --padding: var(--padding-m);
    }
}
