.block-text {
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-xs);
}

.block-text {
    @include font-sans();
    padding: var(--padding-v) var(--padding-h);
    background: var(--grey);

    > *:not(:last-child) {
        margin-bottom: var(--padding-xs);
    }

    a {
        @include basic-a();
        background: var(--pink);
        color: var(--black);
    }
}
